import React from "react";
import "./Courses.css";
import medicalcoding from "../../Assets/medical-coding-vector-concept-color-illustration-icon-developer-working-workspace-table-computer-lab-worker-researching-222506325.jpg";
import cpccertification from "../../Assets/2022_SocialPostDefault_1200x628_v01.jpg";
import medicalspeciality from "../../Assets/642922.jpg";
import ahima from "../../Assets/1678999626415.jfif";
import { Link } from "react-router-dom";
const Courses = () => {
  return (
    <div className="services-card-main">
      <center>
        <h2 style={{ fontSize: "28px", fontFamily: "Tilt Neon" }}>
          All Courses
        </h2>
      </center>
      <ul>
        {/* card1 */}
        <li
          className="booking-card booking-card-main-1"
          style={{
            backgroundImage: `url(${medicalcoding})`,
            backgroundSize: "90%",
            backgroundPosition: "top",

            border: "1px solid red",
            boxShadow: "inset 0 0 10px #f8a100",
            backgroundColor: "#FFFFEF",
          }}
        >
          <div className="book-container">
            <div className="content"></div>
          </div>
          <div className="informations-container">
            <h2 className="title">Medical Coding & Billing</h2>
            <p className="sub-title">
              Medical coding is the transformation of healthcare diagnosis,
              procedures, medical services, and equipment into universal medical
              alphanumeric codes. The diagnoses and procedure codes are taken
              from medical record documentation, such as transcription of
              physician’s notes, laboratory and radiologic results, are
              submitted to health insurance companies for reimbursement. <br />{" "}
              <a
                href="https://www.aapc.com/resources/medical-coding-training"
                style={{ textDecoration: "underline", color: "orangered" }}
              >
                Know More (AAPC)...
              </a>
            </p>
            {/* button */}
            <div className="more-information">
              <div className="info-and-date-container">
                <div className="box info">
                  <div class="our-services-container">
                    <Link
                      to="/medical-coding-nd-billing"
                      class="button-our-services"
                      href="#"
                      style={{ color: "#1e9bff;" }}
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      More ...
                    </Link>
                  </div>
                </div>
              </div>
              <p className="disclaimer">
                We offer customized training programs exclusively tailored for
                both freshers and experienced individuals, with new training
                batches commencing every week.
              </p>
            </div>
          </div>
        </li>
        {/* card-2 */}
        <li
          className="booking-card"
          style={{
            backgroundImage: `url(${cpccertification})`,
            backgroundSize: "100%",

            backgroundRepeat: "no-repeat",
            backgroundPosition: "top",
            border: "1px solid red",
            boxShadow: "inset 0 0 10px #f8a100",
          }}
        >
          <div className="book-container">
            <div className="content"></div>
          </div>
          <div className="informations-container">
            <h2 className="title">CPC Certification</h2>
            <p className="sub-title">
              The CPC Certification, offered by the American Academy of
              Professional Coders (AAPC), is a highly recognized credential in
              the medical coding field. This certification demonstrates
              expertise in accurately assigning medical codes for diagnoses,
              procedures, and services.
              <br />
              <a
                href="https://www.aapc.com/certifications/cpc"
                style={{ textDecoration: "underline", color: "orangered" }}
              >
                Know More (AAPC)...
              </a>
            </p>
            {/* button */}
            <div className="more-information">
              <div className="info-and-date-container">
                <div className="box info">
                  <div class="our-services-container">
                    <Link
                      to={"/cpc-certification"}
                      class="button-our-services"
                      href="#"
                      style={{ color: "#1e9bff;" }}
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      More ...
                    </Link>
                  </div>
                </div>
              </div>
              <p className="disclaimer">
                We offer customized training programs exclusively tailored for
                both freshers and experienced individuals, with new training
                batches commencing every week.
              </p>
            </div>
          </div>
        </li>
        {/* card -3 */}
        <li
          className="booking-card"
          style={{
            backgroundImage: `url(${medicalspeciality})`,
            backgroundSize: "120%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top",
            border: "1px solid red",
            boxShadow: "inset 0 0 10px #f8a100",
          }}
        >
          <div className="book-container">
            <div className="content"></div>
          </div>
          <div className="informations-container">
            <h2 className="title">Medical Specialty Trainings</h2>
            <p className="sub-title">
              medical specialties like Cardiology, Gastroenterology, ENT (Ear,
              Nose, and Throat), Emergency Medicine (EM), Emergency Department
              (ED) Coding, Anesthesia, General Surgery, Oncology, Dermatology,
              and many others. These specialty-specific courses provide in-depth
              knowledge and expertise required to accurately...{" "}
              <a
                href="https://www.aapc.com/shop/specialty-exams.aspx"
                style={{ textDecoration: "underline", color: "orangered" }}
              >
                Know More(AAPC)...
              </a>
            </p>

            <div className="more-information">
              <div className="info-and-date-container">
                <div className="box info">
                  <div class="our-services-container">
                    <Link
                      to={"/medical-speciality"}
                      class="button-our-services"
                      href="#"
                      style={{ color: "#1e9bff;" }}
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      More ...
                    </Link>
                  </div>
                </div>
              </div>
              <p className="disclaimer">
                We offer customized training programs exclusively tailored for
                both freshers and experienced individuals, with new training
                batches commencing every week.
              </p>
            </div>
          </div>
        </li>
        {/* card -4 */}
        <li
          className="booking-card"
          style={{
            backgroundImage: `url(${ahima})`,
            backgroundSize: "100%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top",
            border: "1px solid red",
            boxShadow: "inset 0 0 10px #f8a100",
          }}
        >
          <div className="book-container">
            <div className="content"></div>
          </div>
          <div className="informations-container">
            <h2 className="title">AHIMA UK Certified Coding Specialist</h2>
            <p className="sub-title">
              AHIMA, the American Health Information Management Association, is
              a leading organization dedicated to advancing health information
              management and informatics. They are committed to providing
              resources, education, and expertise in the field of healthcare
              information. <br />
              <a
                href="https://en.wikipedia.org/wiki/American_Health_Information_Management_Association"
                style={{ textDecoration: "underline", color: "orangered" }}
              >
                {" "}
                Know more(wikipedia)...
              </a>
            </p>

            <div className="more-information">
              <div className="info-and-date-container">
                <div className="box info">
                  <div class="our-services-container">
                    <Link
                      to={"/ahima"}
                      class="button-our-services"
                      href="#"
                      style={{ color: "#1e9bff;" }}
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      More ...
                    </Link>
                  </div>
                </div>
              </div>
              <p className="disclaimer">
                We offer customized training programs exclusively tailored for
                both freshers and experienced individuals, with new training
                batches commencing every week.
              </p>
            </div>
          </div>
        </li>
        {/* card 5*/}
      </ul>
    </div>
  );
};

export default Courses;
