import React from "react";
import Contactus from "../../Components/contactUS/Contactus";
import Studentcard2 from "../../Components/StudentCards/Studentcard2";
import { Link } from "react-router-dom";

const Insidemedicalcodingandbilling = () => {
  return (
    <div style={{ backgroundColor: "#FFFFEF" }}>
      <main>
        <div className="position-relative  p-3 p-md-1 m-md-0 text-center ">
          <div className="col-md-8 p-lg-0 mx-auto my-5">
            <h1
              className="display-4 fw-normal"
              style={{ fontSize: "25px", fontFamily: "Tilt Neon" }}
            >
              Medical Coding And Medical Billing
            </h1>
            <p className="">
              Medical coding is the transformation of healthcare diagnosis,
              procedures, medical services, and equipment into universal medical
              alphanumeric codes. The diagnoses and procedure codes are taken
              from medical record documentation, such as transcription of
              physician’s notes, laboratory and radiologic results, are
              submitted to health insurance companies for reimbursement.
            </p>
            <a
              className="btn btn-outline-secondary"
              href="https://www.aapc.com/certifications/cpc"
            >
              Know More{" "}
            </a>
          </div>
          <div className="col-md-8 p-lg-0 mx-auto my-5">
            <h2
              className="display-4 fw-normal"
              style={{ fontSize: "25px", fontFamily: "Tilt Neon" }}
            >
              Learn More About Medical Coding Hyderabad
            </h2>
            <p className="">
              Medical coding is a little bit like translation. Coders take
              medical reports from doctors, which may include a patient’s
              condition, the doctor’s diagnosis, a prescription, and whatever
              procedures the doctor or healthcare provider performed on the
              patient, and turn that into a set of codes, which make up a
              crucial part of the medical claim.
            </p>
            <Link to="/why-code" className="btn btn-outline-secondary">
              {" "}
              Why We Code{" "}
            </Link>
          </div>
          <div className="product-device shadow-sm d-none d-md-block" />
          <div className="product-device product-device-2 shadow-sm d-none d-md-block" />
        </div>
        <div
          className="d-md-flexflex-md-5 w-100 my-md-3 ps-md-3"
          style={{ margin: "auto" }}
        ></div>
      </main>
      <>
        <Contactus />
      </>
      <>
        <Studentcard2 />
      </>
    </div>
  );
};

export default Insidemedicalcodingandbilling;
