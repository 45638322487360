import React from "react";
import WhatsApp from "../../Assets/4-2-whatsapp-transparent.png";

const Whatsapp = () => {
  return (
    <div
      style={{
        position: "fixed",
        zIndex: 1,
        right: "1vh",
        bottom: "1vh",
      }}
    >
      <a href="https://api.whatsapp.com/send/?phone=919502613666&text=Hi&type=phone_number&app_absent=0">
        <img src={WhatsApp} width={"80vh"} />
      </a>
    </div>
  );
};

export default Whatsapp;
