import React from "react";

import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBBtn,
} from "mdb-react-ui-kit";
import "./about.css";
export default function About() {
  return (
    <div
      className="main-aboutus"
      style={{
        backgroundColor: "",
        display: "flex",
        width: "100%",
        margin: "-5vh auto",
      }}
    >
      <MDBContainer style={{ width: "450vh" }}>
        <MDBRow className="justify-content-center">
          <MDBCol md="9 " lg="7" xl="8" className="mt-5 mb-5">
            <MDBCard
              style={{
                borderRadius: "15px",
                border: "1px solid red",
                boxShadow: "inset 0 0 10px #f8a100",
              }}
            >
              <MDBCardBody className="p-4" style={{ backgroundColor: " #fff" }}>
                <div className="d-flex text-black">
                  <div className="flex-shrink-0"></div>
                  <div className="flex-grow-1 ms-3">
                    <center>
                      <MDBCardTitle>
                        <h2
                          style={{ fontSize: "28px", fontFamily: "Tilt Neon" }}
                        >
                          About Us
                        </h2>
                      </MDBCardTitle>
                    </center>

                    <MDBCardText>
                      <center>
                        <p style={{ fontFamily: "Tilt Neon" }}>
                          Best medical coding training institute in hyderabad
                          offers cpc certification with placement assistance by
                          real-time industry experts.At Kriger Medical Coding
                          Institute, we take immense pride in being the best
                          institution for medical coding education in Madhapur.
                          Our journey, steeped in a commitment to excellence,
                          spans over 7+ years, during which we have consistently
                          delivered outstanding results to our students.
                        </p>
                      </center>
                    </MDBCardText>

                    <div
                      className="d-flex justify-content-center rounded-3 p-2 mb-2"
                      style={{
                        backgroundColor: "#efefef",
                      }}
                    >
                      <div>
                        <p className="small text-muted mb-1">
                          Students Trained
                        </p>
                        <p className="mb-0">970+</p>
                      </div>
                      <div className="px-3">
                        <p className="small text-muted mb-1">No Of Courses</p>
                        <p className="mb-0">7+</p>
                      </div>
                      <div className="px-3">
                        <p className="small text-muted mb-1">Expert Trainers</p>
                        <p className="mb-0">13+</p>
                      </div>
                    </div>
                  </div>
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}
