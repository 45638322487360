import React from "react";
import Akshay from "../../Assets/Akshay.jpeg";
import pallavi from "../../Assets/Pallavi.jpeg";
import NagaSree from "../../Assets/meghasree.jpeg";
import Namratha from "../../Assets/Namratha.jpeg";
import BindhuMadhavi from "../../Assets/Bindhu Madhavi.jpeg";
import Mamatha from "../../Assets/Mamatha.jpeg";
import sriLakshmi from "../../Assets/Sri Lakshmi.jpeg";
import Rakesh from "../../Assets/Rakesh.jpeg";
import bindhumadhavi2 from "../../Assets/bindumadhavi2.jpeg";
import "./testimonials.css";
const Testimonial = () => {
  return (
    <div style={{ marginBottom: "3vh" }}>
      <center>
        {" "}
        <h2 style={{ fontSize: "28px", fontFamily: "Tilt Neon" }}>
          {" "}
          Testimonials
        </h2>
      </center>
      {/* Carousel wrapper */}

      {/* Carousel wrapper */}
      <div
        id="carouselExampleControls"
        className="carousel slide text-center carousel-dark"
        data-mdb-ride="carousel"
        data-interval="1000"
        controls="true"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              className="rounded-circle shadow-1-strong mb-4"
              src={Akshay}
              alt="avatar"
              style={{ width: 150 }}
            />
            <div className="row d-flex justify-content-center">
              <div className="col-lg-8">
                <h5 className="mb-3">Akshay</h5>
                <p>Student Certified(Kriger)</p>
                <p className="text-muted">
                  <i className="fas fa-quote-left pe-2" />
                  Best Medical Coding Training Institute & CPC Certification
                  Training Institute In Hyderabad.I have Cleared My
                  Certification in Kriger.{" "}
                  <i className="fas fa-quote-right pe-2" />
                </p>
              </div>
            </div>
            <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
              <li>
                <i className="fas fa-star fa-sm" />
              </li>
              <li>
                <i className="fas fa-star fa-sm" />
              </li>
              <li>
                <i className="fas fa-star fa-sm" />
              </li>
              <li>
                <i className="fas fa-star fa-sm" />
              </li>
              <li>
                <i className="far fa-star fa-sm" />
              </li>
            </ul>
          </div>
          <div className="carousel-item">
            <img
              className="rounded-circle shadow-1-strong mb-4"
              src={pallavi}
              alt="avatar"
              style={{ width: 150 }}
            />
            <div className="row d-flex justify-content-center">
              <div className="col-lg-8">
                <h5 className="mb-3">Pallavi</h5>
                <p>Student Certified(Kriger)</p>
                <p className="text-muted">
                  <i className="fas fa-quote-left pe-2" />
                  Medical Coding Institute In Madhapur and Best CPC
                  Certification Training Institute,I have cleared My CPC
                  Certification here. <i className="fas fa-quote-right pe-2" />
                </p>
              </div>
            </div>
            <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
              <li>
                <i className="fas fa-star fa-sm" />
              </li>
              <li>
                <i className="fas fa-star fa-sm" />
              </li>
              <li>
                <i className="fas fa-star fa-sm" />
              </li>
              <li>
                <i className="fas fa-star fa-sm" />
              </li>
              <li>
                <i className="far fa-star fa-sm" />
              </li>
            </ul>
          </div>
          <div className="carousel-item">
            <img
              className="rounded-circle shadow-1-strong mb-4"
              src={NagaSree}
              alt="avatar"
              style={{ width: 150 }}
            />
            <div className="row d-flex justify-content-center">
              <div className="col-lg-8">
                <h5 className="mb-3">NagaSree</h5>
                <p>Student Certified(Kriger)</p>
                <p className="text-muted">
                  <i className="fas fa-quote-left pe-2" />
                  Best Medical Coding CPC Certification Training Institute In
                  Hyderabad
                  <i className="fas fa-quote-riht pe-2" />
                </p>
              </div>
            </div>
            <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
              <li>
                <i className="fas fa-star fa-sm" />
              </li>
              <li>
                <i className="fas fa-star fa-sm" />
              </li>
              <li>
                <i className="fas fa-star fa-sm" />
              </li>
              <li>
                <i className="fas fa-star fa-sm" />
              </li>
              <li>
                <i className="far fa-star fa-sm" />
              </li>
            </ul>
          </div>
          <div className="carousel-item">
            <img
              className="rounded-circle shadow-1-strong mb-4"
              src={Namratha}
              alt="avatar"
              style={{ width: 150 }}
            />
            <div className="row d-flex justify-content-center">
              <div className="col-lg-8">
                <h5 className="mb-3">Namratha</h5>
                <p>Student Certified(Kriger)</p>
                <p className="text-muted">
                  <i className="fas fa-quote-left pe-2" />
                  Best Medical Coding and CPC Certification Training Institute
                  In Madhapur. i have cleared my CPC Certification.
                  <i className="fas fa-quote-right pe-2" />
                </p>
              </div>
            </div>
            <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
              <li>
                <i className="fas fa-star fa-sm" />
              </li>
              <li>
                <i className="fas fa-star fa-sm" />
              </li>
              <li>
                <i className="fas fa-star fa-sm" />
              </li>
              <li>
                <i className="fas fa-star fa-sm" />
              </li>
              <li>
                <i className="far fa-star fa-sm" />
              </li>
            </ul>
          </div>
          <div className="carousel-item">
            <img
              className="rounded-circle shadow-1-strong mb-4"
              src={BindhuMadhavi}
              alt="avatar"
              style={{ width: 150 }}
            />
            <div className="row d-flex justify-content-center">
              <div className="col-lg-8">
                <h5 className="mb-3">Bindhu Madhavi</h5>
                <p>Student Certified(Kriger)</p>
                <p className="text-muted">
                  <i className="fas fa-quote-left pe-2" />
                  Best Medical Coding and CPC Certification Training Institute
                  In Hyderabad. i have cleared my CPC Certification.{" "}
                  <i className="fas fa-quote-right pe-2" />
                </p>
              </div>
            </div>
            <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
              <li>
                <i className="fas fa-star fa-sm" />
              </li>
              <li>
                <i className="fas fa-star fa-sm" />
              </li>
              <li>
                <i className="fas fa-star fa-sm" />
              </li>
              <li>
                <i className="fas fa-star fa-sm" />
              </li>
              <li>
                <i className="far fa-star fa-sm" />
              </li>
            </ul>
          </div>
          <div className="carousel-item">
            <img
              className="rounded-circle shadow-1-strong mb-4"
              src={Mamatha}
              alt="avatar"
              style={{ width: 150 }}
            />
            <div className="row d-flex justify-content-center">
              <div className="col-lg-8">
                <h5 className="mb-3">Mamatha</h5>
                <p>Student Certified(Kriger)</p>
                <p className="text-muted">
                  <i className="fas fa-quote-left pe-2" />
                  CPC Training Institute In Hyderabad.I have cleared my CPC
                  Certification in Kriger Institute.{" "}
                  <i className="fas fa-quote-right pe-2" />
                </p>
              </div>
            </div>
            <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
              <li>
                <i className="fas fa-star fa-sm" />
              </li>
              <li>
                <i className="fas fa-star fa-sm" />
              </li>
              <li>
                <i className="fas fa-star fa-sm" />
              </li>
              <li>
                <i className="fas fa-star fa-sm" />
              </li>
              <li>
                <i className="far fa-star fa-sm" />
              </li>
            </ul>
          </div>
          <div className="carousel-item">
            <img
              className="rounded-circle shadow-1-strong mb-4"
              src={sriLakshmi}
              alt="avatar"
              style={{ width: 150 }}
            />
            <div className="row d-flex justify-content-center">
              <div className="col-lg-8">
                <h5 className="mb-3">Srilakshmi</h5>
                <p>Student Certified(Kriger)</p>
                <p className="text-muted">
                  <i className="fas fa-quote-left pe-2" />
                  Best Medical Classes In Hyderabad.I have Cleared my CPC
                  Certification in Kriger{" "}
                  <i className="fas fa-quote-right pe-2" />
                </p>
              </div>
            </div>
            <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
              <li>
                <i className="fas fa-star fa-sm" />
              </li>
              <li>
                <i className="fas fa-star fa-sm" />
              </li>
              <li>
                <i className="fas fa-star fa-sm" />
              </li>
              <li>
                <i className="fas fa-star fa-sm" />
              </li>
              <li>
                <i className="far fa-star fa-sm" />
              </li>
            </ul>
          </div>
          <div className="carousel-item">
            <img
              className="rounded-circle shadow-1-strong mb-4"
              src={Rakesh}
              alt="avatar"
              style={{ width: 150 }}
            />
            <div className="row d-flex justify-content-center">
              <div className="col-lg-8">
                <h5 className="mb-3">Rakesh</h5>
                <p>Student Certified(Kriger)</p>
                <p className="text-muted">
                  <i className="fas fa-quote-left pe-2" />
                  Best CPC Certification Training Institute In Hyderabad{" "}
                  <i className="fas fa-quote-right pe-2" />
                </p>
              </div>
            </div>
            <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
              <li>
                <i className="fas fa-star fa-sm" />
              </li>
              <li>
                <i className="fas fa-star fa-sm" />
              </li>
              <li>
                <i className="fas fa-star fa-sm" />
              </li>
              <li>
                <i className="fas fa-star fa-sm" />
              </li>
              <li>
                <i className="far fa-star fa-sm" />
              </li>
            </ul>
          </div>
          <div className="carousel-item">
            <img
              className="rounded-circle shadow-1-strong mb-4"
              src={bindhumadhavi2}
              alt="avatar"
              style={{ width: 150 }}
            />
            <div className="row d-flex justify-content-center">
              <div className="col-lg-8">
                <h5 className="mb-3">Bindgu Madhavi</h5>
                <p>Student Certified(Kriger)</p>
                <p className="text-muted">
                  <i className="fas fa-quote-left pe-2" />
                  Top Medical Coding & CPC Certification Training Institute In
                  Madhapur,Hyderabad <i className="fas fa-quote-right pe-2" />
                </p>
              </div>
            </div>
            <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
              <li>
                <i className="fas fa-star fa-sm" />
              </li>
              <li>
                <i className="fas fa-star fa-sm" />
              </li>
              <li>
                <i className="fas fa-star fa-sm" />
              </li>
              <li>
                <i className="fas fa-star fa-sm" />
              </li>
              <li>
                <i className="far fa-star fa-sm" />
              </li>
            </ul>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-mdb-target="#carouselExampleControls"
          data-mdb-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-mdb-target="#carouselExampleControls"
          data-mdb-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="visually-hidden">Next</span>
        </button>
      </div>
      {/* Carousel wrapper */}

      {/* Carousel wrapper */}
    </div>
  );
};

export default Testimonial;
