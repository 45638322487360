import React from "react";
import "./contact.css";
import { useState } from "react";
import axios from "axios";

const Contactus = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const submitHandler = (e) => {
    e.preventDefault();
    axios
      .post(
        "https://kriger-9a501-default-rtdb.firebaseio.com/register.json",
        formData
      )
      .then(() => alert("Submitted Successfully"));
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };
  return (
    <div id="contact-us">
      <section id="contact" className="contact">
        {/*  Section Title */}
        <div className="container section-title" data-aos="fade-up">
          <center>
            <p style={{ fontFamily: "Tilt Neon", fontSize: "25px" }}>
              {" "}
              Locate US
            </p>
          </center>
        </div>
        {/* End Section Title */}
        <div className="mapouter">
          <div className="gmap_canvas">
            <div className="mapouter">
              <div className="gmap_canvas" style={{ backgroundColor: "red" }}>
                <iframe
                  src="https://maps.google.com/maps?q=kriger%20medical%20coding&t=&z=11&ie=UTF8&iwloc=&output=embed"
                  frameBorder={0}
                  scrolling="no"
                  style={{ width: "100%", height: 400 }}
                  title="Medical Coding Training Institute Madhapur Hyderabad"
                />

                <style
                  dangerouslySetInnerHTML={{
                    __html:
                      ".gmap_canvas{overflow:hidden;height:450px;width:1010px}.gmap_canvas iframe{position:relative;z-index:2}",
                  }}
                />
              </div>
            </div>
          </div>
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".mapouter{position:relative;text-align:right;width:100%;height:450px;}.gmap_canvas {overflow:hidden;background:none!important;width:100%;height:450px;}.gmap_iframe {height:450px!important;}",
            }}
          />
        </div>
      </section>
      <section id="contact" className="contact">
        {/*  Section Title */}
        <div className="container section-title" data-aos="fade-up">
          <center>
            <p style={{ fontFamily: "Tilt Neon", fontSize: "25px" }}>
              {" "}
              Contact US
            </p>
          </center>
        </div>
        {/* End Section Title */}
        <div className="container" data-aos="fade-up" data-aos-delay={100}>
          <div className="row gy-4">
            <div className="col-lg-6">
              <div className="row gy-4">
                <div className="col-md-8">
                  <center>
                    <div
                      className="info-item"
                      data-aos="fade"
                      data-aos-delay={300}
                      style={{
                        boxShadow: "inset 0 0 10px #f8a100",
                        borderRadius: "25px",
                      }}
                    >
                      <h3
                        style={{ fontFamily: "Tilt Neon", marginTop: "0.5vh" }}
                      >
                        Address
                      </h3>
                      <p style={{ fontFamily: "Tilt Neon" }}>
                        Flat B3,3rd Floor,KVR Enclave,Beside Gurudwara,Ameerpet Hyderabad 500016
                      </p>
                    </div>
                  </center>
                </div>
                {/* End Info Item */}
                <div className="col-md-4">
                  <center>
                    <div
                      className="info-item"
                      data-aos="fade"
                      data-aos-delay={300}
                      style={{
                        boxShadow: "inset 0 0 10px #f8a100",
                        borderRadius: "25px",
                      }}
                    >
                      <i className="bi bi-telephone" />
                      <h3
                        style={{ fontFamily: "Tilt Neon", marginTop: "0.5vh" }}
                      >
                        Call Us
                      </h3>
                      <p style={{ fontFamily: "Tilt Neon" }}>+91 70750 40344</p>
                      <p style={{ fontFamily: "Tilt Neon" }}>+91 95026 13666</p>
                    </div>
                  </center>
                </div>
                {/* End Info Item */}
                <div className="col-md-4">
                  <center>
                    <div
                      className="info-item"
                      data-aos="fade"
                      data-aos-delay={400}
                      style={{
                        boxShadow: "inset 0 0 10px #f8a100",
                        borderRadius: "25px",
                      }}
                    >
                      <i className="bi bi-envelope" />
                      <h3
                        style={{ fontFamily: "Tilt Neon", marginTop: "0.5vh" }}
                      >
                        Email Us
                      </h3>
                      <p style={{ fontFamily: "Tilt Neon" }}>
                        info@krigerindia.com
                      </p>
                    </div>
                  </center>
                </div>
                {/* End Info Item */}
                <div className="col-md-4">
                  <center>
                    <div
                      className="info-item"
                      data-aos="fade"
                      data-aos-delay={500}
                      style={{
                        boxShadow: "inset 0 0 10px #f8a100",
                        borderRadius: "25px",
                      }}
                    >
                      <i className="bi bi-clock" />
                      <h3
                        style={{ fontFamily: "Tilt Neon", marginTop: "0.5vh" }}
                      >
                        Open Hours
                      </h3>
                      <p style={{ fontFamily: "Tilt Neon" }}>Monday - Friday</p>
                      <p style={{ fontFamily: "Tilt Neon" }}>
                        9:00AM - 07:00PM
                      </p>
                    </div>
                  </center>
                </div>
                <div className="col-md-4">
                  <center>
                    <div
                      className="info-item"
                      data-aos="fade"
                      data-aos-delay={500}
                      style={{
                        boxShadow: "inset 0 0 10px #f8a100",
                        borderRadius: "25px",
                      }}
                    >
                      <i className="bi bi-clock" />
                      <h3
                        style={{ fontFamily: "Tilt Neon", marginTop: "0.5vh" }}
                      >
                        Open Hours
                      </h3>
                      <p style={{ fontFamily: "Tilt Neon" }}>Saturday</p>
                      <p style={{ fontFamily: "Tilt Neon" }}>
                        9:00AM - 06:30PM
                      </p>
                    </div>
                  </center>
                </div>
                {/* End Info Item */}
              </div>
            </div>
            <div className="col-lg-6">
              <form
                action="connect.php"
                method="post"
                className="php-email-form"
                data-aos="fade-up"
                data-aos-delay={200}
                onSubmit={submitHandler}
              >
                <div className="row gy-4">
                  <div className="col-md-6">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Your Name"
                      required=""
                      style={{ borderRadius: "25vh" }}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6 ">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="Your Email"
                      required=""
                      style={{ borderRadius: "25vh" }}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-12">
                    <input
                      type="phone"
                      className="form-control"
                      name="mobile"
                      placeholder="Your Number"
                      required=""
                      style={{
                        borderRadius: "25vh",
                        border: "1px solid black",
                      }}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-12">
                    <textarea
                      className="form-control"
                      name="message"
                      rows={6}
                      placeholder="Message"
                      required=""
                      style={{ borderRadius: "2vh" }}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-12 text-center">
                    <button
                      type="submit"
                      className="btn btn-success"
                      style={{ borderRadius: "5vh" }}
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
            {/* End Contact Form */}
          </div>
        </div>
      </section>

      {/* End Contact Section */}
    </div>
  );
};

export default Contactus;
