import React from "react";

const Scroll = () => {
  return (
    <div style={{ backgroundColor: "orange", color: "#fff" }}>
      <marquee>
        <b>Welcome To Kriger Medical Coding Training Institute at Madhapur! </b>{" "}
        At Kriger Medical Coding Institute, we conduct crash courses twice a
        week, to both Fresher's and Exprerienced individuals. Contact: +91-
        95026 93666, 70750 40344 (Call or Whatsapp)
      </marquee>
    </div>
  );
};

export default Scroll;
