import React from "react";
const Faqcertification = () => {
  return (
    <div className="faq" style={{ width: "80%", margin: "auto" }}>
      <div className="accordion " id="basicAccordion">
        <center>
          {" "}
          <h3 style={{ marginTop: "15px", fontFamily: "Tilt Neon" }}>
            Frequently Asked Question's
          </h3>
        </center>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button collapsed"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#basicAccordionCollapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              What is CPC Exam Format{" "}
            </button>
          </h2>
          <div
            id="basicAccordionCollapseOne"
            className="accordion-collapse collapse"
            aria-labelledby="headingOne"
            data-mdb-parent="#basicAccordion"
            style={{}}
          >
            <div className="accordion-body">
              <strong>
                100 multiple-choice .{" "}
                <a href="https://www.aapc.com/certifications/cpc">
                  know more...
                </a>
              </strong>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button collapsed"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#basicAccordionCollapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Online or in-person options
            </button>
          </h2>
          <div
            id="basicAccordionCollapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-mdb-parent="#basicAccordion"
            style={{}}
          >
            <div className="accordion-body">
              AAPC offers online and in-person proctored exams. Choose to take
              the exam at home in a quiet, private location or through your
              local chapter or a licensed instructor. Learn more about the
              online proctored exam and how to prepare for the current exam.{" "}
              <a href="https://www.aapc.com/certifications/cpc">know more...</a>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button
              className="accordion-button collapsed"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#basicAccordionCollapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              Time allowed for CPC Exam?{" "}
            </button>
          </h2>
          <div
            id="basicAccordionCollapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-mdb-parent="#basicAccordion"
            style={{}}
          >
            <div className="accordion-body">
              In-person and online exams are administered in one sitting, with
              four hours to complete the exam.{" "}
              <a href="https://www.aapc.com/certifications/cpc">know more...</a>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="heading4">
            <button
              className="accordion-button collapsed"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#basicAccordionCollapse4"
              aria-expanded="false"
              aria-controls="collapse4"
            >
              Equipment required for online exam{" "}
            </button>
          </h2>
          <div
            id="basicAccordionCollapse4"
            className="accordion-collapse collapse"
            aria-labelledby="heading4"
            data-mdb-parent="#basicAccordion"
            style={{}}
          >
            <div className="accordion-body">
              A reliable internet connection and an external webcam that can be
              positioned to show your face, hands, keyboard, and the area around
              the keyboard (about 10 inches) are required.{" "}
              <a href="https://www.aapc.com/certifications/cpc">know more...</a>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="heading5">
            <button
              className="accordion-button collapsed"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#basicAccordionCollapse5"
              aria-expanded="false"
              aria-controls="collapse5"
            >
              Experience requirements
            </button>
          </h2>
          <div
            id="basicAccordionCollapse5"
            className="accordion-collapse collapse"
            aria-labelledby="heading5"
            data-mdb-parent="#basicAccordion"
            style={{}}
          >
            <div className="accordion-body">
              Training requires a high-level knowledge of medical terminology,
              anatomy, and pathophysiology. If you don’t have medical experience
              or working knowledge of these subjects, we offer prerequisite
              courses to prepare you for certification training.{" "}
              <a href="https://www.aapc.com/certifications/cpc">know more...</a>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="heading6">
            <button
              className="accordion-button collapsed"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#basicAccordionCollapse6"
              aria-expanded="false"
              aria-controls="collapse6"
            >
              Approved code books
            </button>
          </h2>
          <div
            id="basicAccordionCollapse6"
            className="accordion-collapse collapse"
            aria-labelledby="heading6"
            data-mdb-parent="#basicAccordion"
            style={{}}
          >
            <div className="accordion-body">
              Course Textbook: eBook (included w/purchase) or print textbook
              (available for purchase) AMA's CPT® Professional Edition (current
              year) ICD-10-CM (current year), any publisher HCPCS Level II
              (current year), any publisher Note: Code sets are updated
              annually, so it’s essential to use the current calendar year's
              code books when taking the CPC certification exam. Any officially
              published updates (errata) for the above code books may also be
              used.{" "}
              <a href="https://www.aapc.com/certifications/cpc">know more...</a>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="heading7">
            <button
              className="accordion-button collapsed"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#basicAccordionCollapse7"
              aria-expanded="false"
              aria-controls="collapse7"
            >
              Maintaining your certification{" "}
            </button>
          </h2>
          <div
            id="basicAccordionCollapse7"
            className="accordion-collapse collapse"
            aria-labelledby="heading7"
            data-mdb-parent="#basicAccordion"
            style={{}}
          >
            <div className="accordion-body">
              To maintain your dual credentials, you must maintain your AAPC
              annual membership, and earn 40 continuing education units (CEUs)
              every two years.{" "}
              <a href="https://www.aapc.com/certifications/cpc">know more...</a>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="heading8">
            <button
              className="accordion-button collapsed"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#basicAccordionCollapse8"
              aria-expanded="false"
              aria-controls="collapse8"
            >
              Pricing?
            </button>
          </h2>
          <div
            id="basicAccordionCollapse8"
            className="accordion-collapse collapse"
            aria-labelledby="heading8"
            data-mdb-parent="#basicAccordion"
            style={{}}
          >
            <div className="accordion-body">
              Both exams need to be scheduled and purchased separately. The cost
              for each certification exam is the same: One attempt: $399 ($798
              total for both exams) Two attempts: $499 ($998 total for both
              exams) Note: 80% of AAPC students pass on the first attempt.
              Students who train with AAPC are three times more likely to pass
              the certification exam.
              <a href="https://www.aapc.com/certifications/cpc">know more...</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faqcertification;
