import React from "react";

const InsideMedicalSpeciality = () => {
  return (
    <div>
      <div style={{ backgroundColor: "#FFFFEF" }}>
        <main>
          <div className="position-relative  p-3 p-md-1 m-md-0 text-center ">
            <div className="col-md-8 p-lg-0 mx-auto my-5">
              <h1
                className="display-4 fw-normal"
                style={{ fontSize: "25px", fontFamily: "Tilt Neon" }}
              >
                Medical Coding Specialty Training,Hyderabad
              </h1>
            </div>
            <div className="product-device shadow-sm d-none d-md-block" />
            <div className="product-device product-device-2 shadow-sm d-none d-md-block" />
          </div>
          <>
            <div className="d-md-flexflex-md-2 w-100  ps-md-3">
              <p
                style={{
                  margin: "-4.5vh 4vh",
                  fontSize: "2.3vh",
                }}
              >
                <ol style={{ listStyle: "square" }}>
                  <li>
                    Cardiology, Gastroenterology, ENT, EM, ED Coding,
                    Anesthesia, General Surgery, Oncology, Dermatology Coding
                    Training
                  </li>
                  <li>
                    Location: Conveniently located in Madhapur, Hyderabad, our
                    institute offers a conducive learning environment with
                    modern facilities and experienced instructors. Why Choose
                    Kriger Medical Coding Institute?
                  </li>
                  <li>AAPC’s Certified Professional Coder (CPC)</li>
                  <li>ICD-10-CM,PCS,IP-DRG, Hospital Coding, UB-04</li>
                  <li>
                    Medical Coding Auditing Training, HIM Principles, CCS Mock
                    Exams etc
                  </li>
                  <li>Comprehensive Medical Coding and Billing</li>
                  <li>Medical Terminology, ICD-10-CM, CPT, HCPCS,</li>
                  <li>
                    Medical Billing, HIPAA, Medical Coding Practice, CPC Exam
                    Preparation etc
                  </li>
                  <li>Comprehensive Medical Coding and Billing</li>
                </ol>{" "}
              </p>
            </div>
            <div className="d-md-flexflex-md-2 w-100  ps-md-3">
              <center>
                {" "}
                <h2
                  style={{
                    fontSize: "23px",
                    fontFamily: "Tilt Neon",
                    marginTop: "5.5vh",
                  }}
                >
                  How do I get my CPC certification?
                </h2>
              </center>

              <p
                style={{
                  margin: "2vh 4vh",

                  fontSize: "2.3vh",
                }}
              >
                The CPC® credential is the most recognized medical coding
                certification in the healthcare industry.
                <br />
                <b>This is achieved in four steps.</b>
                <ol style={{ listStyle: "square" }}>
                  <li>Step 1 Pass the CPC® Exam.</li>
                  <li>Step 2 Become and Maintain AAPC Membership</li>
                  <li>Step 3 Verify Your Experience.</li>
                  <li>
                    Step 4 Maintain Certification Through Continuing Education
                    Units (CEUs)
                  </li>
                </ol>{" "}
              </p>
            </div>
            <div className="d-md-flexflex-md-2 w-100  ps-md-3">
              <center>
                {" "}
                <h2 style={{ fontSize: "23px", fontFamily: "Tilt Neon" }}>
                  How much does Medical Coding Training Fees Hyderabad?
                </h2>
              </center>

              <p
                style={{
                  margin: "2vh 4vh",

                  fontSize: "2.3vh",
                }}
              >
                <center>
                  Fees for Medical coding 28,000+ only. If you are Looking for
                  CPC certification training,
                </center>
              </p>
            </div>
          </>
        </main>
      </div>
    </div>
  );
};

export default InsideMedicalSpeciality;
