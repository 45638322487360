import React from "react";
import "./Faq.css";
const Faq = () => {
  return (
    <div className="faq" style={{ width: "80%", margin: "auto" }}>
      <div className="accordion " id="basicAccordion">
        <center>
          {" "}
          <h3 style={{ marginTop: "15px", fontFamily: "Tilt Neon" }}>
            Frequently Asked Question's
          </h3>
        </center>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button collapsed"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#basicAccordionCollapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              What is CPC certification?{" "}
            </button>
          </h2>
          <div
            id="basicAccordionCollapseOne"
            className="accordion-collapse collapse"
            aria-labelledby="headingOne"
            data-mdb-parent="#basicAccordion"
            style={{}}
          >
            <div className="accordion-body">
              <strong>CPC stands for Certified Professional Coder.</strong> This
              medical coding certification is offered through the American
              Academy of Professional Coders (AAPC) and serves to provide a sort
              of evidence that you have the knowledge and skills needed to work
              as a professional coder.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button collapsed"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#basicAccordionCollapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              What does the AAPC stand for?{" "}
            </button>
          </h2>
          <div
            id="basicAccordionCollapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-mdb-parent="#basicAccordion"
            style={{}}
          >
            <div className="accordion-body">
              The AAPC, previously known by the full title of the American
              Academy of Professional Coders, is a professional association for
              people working in specific areas of administration within
              healthcare businesses in the United States.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button
              className="accordion-button collapsed"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#basicAccordionCollapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              Is medical coding good for freshers?{" "}
            </button>
          </h2>
          <div
            id="basicAccordionCollapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-mdb-parent="#basicAccordion"
            style={{}}
          >
            <div className="accordion-body">
              Medical Coding provides tremendous opportunities to all talented
              Life science and Paramedical graduates who have passion for
              Healthcare industry.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="heading4">
            <button
              className="accordion-button collapsed"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#basicAccordionCollapse4"
              aria-expanded="false"
              aria-controls="collapse4"
            >
              What is medical coding salary in Hyderabad for freshers?{" "}
            </button>
          </h2>
          <div
            id="basicAccordionCollapse4"
            className="accordion-collapse collapse"
            aria-labelledby="heading4"
            data-mdb-parent="#basicAccordion"
            style={{}}
          >
            <div className="accordion-body">
              Medical Coding Specialist salary in Hyderabad/Secunderabad with
              less than 1 year of experience to 7 years ranges from ₹ 2.2 Lakhs
              to ₹ 7.0 Lakhs with an average annual salary of ₹ 4.6 Lakhs based
              on 130 latest salaries.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="heading5">
            <button
              className="accordion-button collapsed"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#basicAccordionCollapse5"
              aria-expanded="false"
              aria-controls="collapse5"
            >
              What does a medical coder do?{" "}
            </button>
          </h2>
          <div
            id="basicAccordionCollapse5"
            className="accordion-collapse collapse"
            aria-labelledby="heading5"
            data-mdb-parent="#basicAccordion"
            style={{}}
          >
            <div className="accordion-body">
              A medical coder translates details from a patient's medical
              documents, such as physician's notes, lab reports, procedures, and
              diagnoses into universal medical codes to maintain accurate
              medical records. Healthcare providers and insurance companies use
              these standardized codes for billing and record-keeping.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="heading6">
            <button
              className="accordion-button collapsed"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#basicAccordionCollapse6"
              aria-expanded="false"
              aria-controls="collapse6"
            >
              Is medical coding a permanent job?{" "}
            </button>
          </h2>
          <div
            id="basicAccordionCollapse6"
            className="accordion-collapse collapse"
            aria-labelledby="heading6"
            data-mdb-parent="#basicAccordion"
            style={{}}
          >
            <div className="accordion-body">
              Medical coding is not just a career, it is also a lifetime
              assurance that you will have a job that is secured.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="heading7">
            <button
              className="accordion-button collapsed"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#basicAccordionCollapse7"
              aria-expanded="false"
              aria-controls="collapse7"
            >
              What is the CPC exam for?{" "}
            </button>
          </h2>
          <div
            id="basicAccordionCollapse7"
            className="accordion-collapse collapse"
            aria-labelledby="heading7"
            data-mdb-parent="#basicAccordion"
            style={{}}
          >
            <div className="accordion-body">
              The CPC exam is a test of medical coding proficiency consisting of
              100 multiple-choice questions that assess 17 areas of knowledge.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="heading8">
            <button
              className="accordion-button collapsed"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#basicAccordionCollapse8"
              aria-expanded="false"
              aria-controls="collapse8"
            >
              Is CPC exam open book?{" "}
            </button>
          </h2>
          <div
            id="basicAccordionCollapse8"
            className="accordion-collapse collapse"
            aria-labelledby="heading8"
            data-mdb-parent="#basicAccordion"
            style={{}}
          >
            <div className="accordion-body">
              The CPC exam is open book, and everything you need to know is in
              your CPT®, ICD-10-CM, and HCPCS Level II code books. The more
              intimately you know your books, the kinder time will be to you on
              exam day.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="heading9">
            <button
              className="accordion-button collapsed"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#basicAccordionCollapse9"
              aria-expanded="false"
              aria-controls="collapse9"
            >
              What is the minimum qualification for CPC?{" "}
            </button>
          </h2>
          <div
            id="basicAccordionCollapse9"
            className="accordion-collapse collapse"
            aria-labelledby="heading9"
            data-mdb-parent="#basicAccordion"
            style={{}}
          >
            <div className="accordion-body">
              Eligibility Criteria For Medical Coding CPC Certification:
              Applicants are recommended to have one of the following life
              Science degrees: Bachelor's, Master s, or PhD, MBBS/ MD/B.D.S
              /M.D.S/ B.A.M.S / B.H.M.S /B.P.T/ B. Tech (Biotechnology /
              Pharmaceutical Science)
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="heading10">
            <button
              className="accordion-button collapsed"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#basicAccordionCollapse10"
              aria-expanded="false"
              aria-controls="collapse10"
            >
              What is the salary of CPC jobs in India?{" "}
            </button>
          </h2>
          <div
            id="basicAccordionCollapse10"
            className="accordion-collapse collapse"
            aria-labelledby="heading10"
            data-mdb-parent="#basicAccordion"
            style={{}}
          >
            <div className="accordion-body">
              How much does a Cpc make in India? The average cpc salary in India
              is ₹ 410,000 per year or ₹ 164 per hour. Entry-level positions
              start at ₹ 350,000 per year, while most experienced workers make
              up to ₹ 630,000 per year.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="heading11">
            <button
              className="accordion-button collapsed"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#basicAccordionCollapse11"
              aria-expanded="false"
              aria-controls="collapse11"
            >
              What to do after passing CPC?{" "}
            </button>
          </h2>
          <div
            id="basicAccordionCollapse11"
            className="accordion-collapse collapse"
            aria-labelledby="heading11"
            data-mdb-parent="#basicAccordion"
            style={{}}
          >
            <div className="accordion-body">
              After getting your CPC® certification Complete your
              apprenticeship. Network with AAPC members and organizations.
              Maintain your certification. Find your dream job. Advance your
              career with lifelong learning.
            </div>
          </div>
        </div>
      </div>
      <>
        <br></br>
      </>
    </div>
  );
};

export default Faq;
