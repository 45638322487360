import React from "react";
import Hero from "../Hero/Hero";
import Footer from "../Footer/Footer";
import Whatsapp from "../socialmedia/Whatsapp";
import { Helmet } from "react-helmet";
const Home = () => {
  return (
    <div>
      <Helmet>
        <meta
          property="og:title"
          content="kriger medical coding training  institute"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://krigerindia.com/" />
        <title>
          Kriger Best CPC Certification Training Institute in Madhapur | Kriger
          India
        </title>
        <meta
          name="description"
          content="Get the best CPC certification training in Hyderabad and Madhapur with Kriger India. Learn how to get CPC certification, its benefits, and more."
        />
        <meta
          name="keywords"
          content="best CPC certification training institute in Hyderabad, best CPC certification training institute in Madhapur, best CPC certification training in Madhapur, CPC certification training in Madhapur, best CPC certification training in hyderabad, best CPC training institute in chennai, cpc certification training in hyderabad, cpc training institutes in hyderabad, cpc certification in hyderabad, how do I get CPC certification, how to get CPC certification, how long does it take to get a CPC certification, is CPC certification worth it, hyderabad best training institute, hyderabad best it institute, hyderabad it trainings, hyderabad it training institute, Best medical coding training institute in Hyderabad, Best Medical coding training institute in Madhapur, top medical coding training institutes in Hyderabad, medical coding classes in Madhapur, best medical coding coaching centers in Hyderabad, best medical coding coaching centers in Madhapur,medical coding course fees,medical coding institute in hyderabad,medical coding institutes in hyd,medical coding coaching in hyderabad,medical coding institutes in hyderabad,online medical coding courses,medical coding training,government approved medical coding course,medical coding course duration and fees,medical coding training in hyderabad,medical coding course online free with certificate,best medical coding institute in hyderabad,medical coding training fees in hyderabad,best medical coding training in hyderabad,best medical coding training institute in hyderabad,free medical coding training in hyderabad,cpc certification course,medical coding course duration and fees in hyderabad,medical coding,medical coding coaching centers in hyderabad training with placement,BEST MEDICAL CODING TRAINING INSTITUTE IN HYDERABAD,
          MEDICAL CODING TRAINING INSTITUTE IN HYDERABAD,
          BEST MEDICAL CODING INSTITUTE IN HYDERABAD,
          MEDICAL CODING INSTITUTE IN HYDERABAD,
          TOP MEDICAL CODING TRAINING INSTITUTE IN HYDERABAD,
          TOP MEDICAL CODING INSTITUTE IN HYDERABAD,
          BEST MEDICAL CODING CLASSES IN HYDERABAD,
          MEDICAL CODING CLASSES IN HYDERABAD,
          BEST MEDICAL CODING COURSE IN HYDERABAD,
          MEDICAL CODING COURSE IN HYDERABAD,
          BEST MEDICAL CODING CPC CERTIFICATION TRAINING INSTITUTE IN HYDERABAD,
          BEST CPC CERTIFICATION TRAINING INSTITUTE IN HYDERABAD,
          CPC CERTIFICATION TRAINING INSTITUTE IN HYDERABAD,
          CPC TRAINING INSTITUTE IN HYDERABAD,
          CPC TRAINING IN HYDERABAD,
          TOP MEDICAL CODING CLASSES IN HYDERABAD,
          TOP MEDICAL CODING COURSES IN HYDERABAD,
          MEDICAL CODING TRAINING,
          MEDICAL CODING TRAINING INSTITUTE,
          MEDICAL CODING INSTITUTE,
          BEST MEDICAL CODING INSTITUTE,
          BEST MEDICAL CODING COACHING CENTER IN HYDERABAD,
          MEDICAL CODING COACHING CENTER IN HYDERABAD,
          TOP MEDICAL CODING COACHING CENTER IN HYDERABAD,
          TOP CPC CERITIFICATION TRAINING INSTITUTE IN HYDERABAD,
          TOP CPC TRAINING INSTITUTE IN HYDERABAD"
        />
      </Helmet>
      <Hero />
      <Footer />
      <Whatsapp />
    </div>
  );
};

export default Home;
