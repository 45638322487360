import React from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import krigerlogo from "../Assets/logo-wide-removebg-preview (1).png";
const Navbar = () => {
  return (
    <div
      style={{
        backgroundColor: "#FFF",
      }}
    >
      <nav
        class="navbar navbar-expand-lg sticky-top "
        style={{ border: "none", position: "relative" }}
      >
        <div class="container-fluid">
          <Link>
            <img
              className="img-fluid"
              alt="kriger-logo"
              src={krigerlogo}
              width={"250vh"}
              height={"120%"}
            />
          </Link>

          <button
            class="navbar-toggler"
            type="button"
            data-mdb-toggle="collapse"
            data-mdb-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style={{
              border: "1px solid white",
            }}
          >
            <i class="fas fa-bars"></i>
          </button>
          <div
            class="collapse navbar-collapse"
            id="navbarNavAltMarkup"
            style={{ marginLeft: "15vh", color: "#b64d1df4" }}
          >
            <div class="navbar-nav">
              <Link to={"/"} style={{ color: "#b64d1df4" }}>
                Home
              </Link>

              <li
                class="nav-item dropdown"
                style={{ marginTop: "-1vh", width: "fit-content" }}
              >
                <a
                  class="nav-link dropdown-toggle"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-mdb-toggle="dropdown"
                  aria-expanded="false"
                >
                  <Link to={"/"} style={{ color: "#b64d1df4" }}>
                    Our Courses
                  </Link>
                </a>
                <div
                  class="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                  style={{
                    marginLeft: "1vh",
                    width: "15vh",
                  }}
                >
                  <center>
                    <li>
                      <Link
                        to={"/medical-coding-nd-billing"}
                        style={{
                          marginLeft: "0.5vh",
                          marginBottom: "1.85vh",
                          width: "fit-content",
                          textDecoration: "underline",
                        }}
                      >
                        Medical Coding & Billing
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/cpc-certification"}
                        style={{
                          marginLeft: "1vh",
                          marginBottom: "1vh",
                          textDecoration: "underline",
                        }}
                      >
                        CPC Certification
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/medical-speciality"}
                        style={{
                          marginLeft: "1vh",
                          marginBottom: "1vh",
                          textDecoration: "underline",
                        }}
                      >
                        Medical Speciality Training
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/ahima"
                        style={{
                          marginLeft: "1vh",
                          marginBottom: "1vh",
                          textDecoration: "underline",
                        }}
                      >
                        AHIMA UK Certified Coding Specialist
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/australian-clinical-coding"}
                        style={{
                          marginLeft: "1vh",
                          marginBottom: "1vh",
                          textDecoration: "underline",
                        }}
                      >
                        Australian Clinial Coding
                      </Link>
                    </li>
                  </center>
                </div>
              </li>
              <Link to={"/cpc-certification"} style={{ color: "#b64d1df4" }}>
                Certification's
              </Link>

              <Link to={"/why-kriger-coding"} style={{ color: "#b64d1df4" }}>
                Why Kriger
              </Link>
              <Link to="/error" style={{ color: "#b64d1df4" }}>
                Trainer Registration
              </Link>

              <Link to="/contact-us" style={{ color: "#b64d1df4" }}>
                Contact Us
              </Link>

              <Link
                to={"/reach-us-now"}
                style={{ color: "#b64d1df4", textDecoration: "underline" }}
              >
                Reach Us Now
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
