import React from "react";
import Studentcard2 from "../../Components/StudentCards/Studentcard2";
import Footer from "../../Components/Footer/Footer";
import Loading from "./Loading";
import Contactus from "../../Components/contactUS/Contactus";
import { Helmet } from "react-helmet";

const Error = () => {
  return (
    <div style={{ backgroundColor: "#FFFFEF" }}>
      <Helmet>
        <title>
          Kriger Best CPC Certification Training Institute in Madhapur | Kriger
          India
        </title>
        <meta
          name="description"
          content="Get the best CPC certification training in Hyderabad and Madhapur with Kriger India. Learn how to get CPC certification, its benefits, and more."
        />
        <meta
          name="keywords"
          content="best CPC certification training institute in Hyderabad, best CPC certification training institute in Madhapur, best CPC certification training in Madhapur, CPC certification training in Madhapur, best CPC certification training in hyderabad, best CPC training institute in chennai, cpc certification training in hyderabad, cpc training institutes in hyderabad, cpc certification in hyderabad, how do I get CPC certification, how to get CPC certification, how long does it take to get a CPC certification, is CPC certification worth it, hyderabad best training institute, hyderabad best it institute, hyderabad it trainings, hyderabad it training institute, Best medical coding training institute in Hyderabad, Best Medical coding training institute in Madhapur, top medical coding training institutes in Hyderabad, medical coding classes in Madhapur, best medical coding coaching centers in Hyderabad, best medical coding coaching centers in Madhapur"
        />
      </Helmet>

      <>
        <Loading />
      </>
      <>
        <>
          <Contactus />
        </>
      </>
      <>
        <Studentcard2 />
      </>
      <>
        <Footer />
      </>
    </div>
  );
};

export default Error;
