import React from "react";
import Contactus from "../../Components/contactUS/Contactus";
import Studentcard2 from "../../Components/StudentCards/Studentcard2";
const InsideWhyKriger = () => {
  return (
    <div style={{ backgroundColor: "#FFFFEF" }}>
      <main>
        <div className="position-relative  p-3 p-md-1 m-md-0 text-center ">
          <div className="col-md-8 p-lg-0 mx-auto my-5">
            <h1
              className="display-4 fw-normal"
              style={{ fontSize: "25px", fontFamily: "Tilt Neon" }}
            >
              Welcome to Kriger Medical Coding Institute, Your Pathway to
              Success!{" "}
            </h1>
            <p className="" style={{ fontSize: "2.4vh" }}>
              At Kriger Medical Coding Institute, we are committed to
              transforming aspiring individuals into skilled and certified
              medical coders. With over 7 years of industry experience, we have
              established ourselves as a trusted and reputable institution in
              the field of medical coding and CPC (Certified Professional Coder)
              certification training.and also in Medical Speciality Training.
            </p>
            <a
              className="btn btn-outline-secondary"
              href="https://www.aapc.com/certifications/cpc"
            >
              Know More{" "}
            </a>
          </div>
          <div className="product-device shadow-sm d-none d-md-block" />
          <div className="product-device product-device-2 shadow-sm d-none d-md-block" />
        </div>
        <div
          className="d-md-flexflex-md-5 w-100 my-md-3 ps-md-3"
          style={{ margin: "auto", justifyContent: "center" }}
        >
          <center>
            {" "}
            <h2
              style={{
                fontSize: "25px",
                fontFamily: "Tilt Neon",
                marginTop: "-7vh",
              }}
            >
              Our Exclusive Programs:
            </h2>
          </center>
          <center>
            <p
              style={{
                margin: "2vh 4vh",
                textIndent: "5vh",
                fontSize: "2.4vh",
              }}
            >
              {" "}
              We understand the unique needs of freshers entering the healthcare
              coding industry, and that's why we have designed exclusive
              programs tailored to your requirements. Our comprehensive training
              programs are not only geared towards helping you ace the CPC
              certification exam but also ensuring that you have the skills and
              knowledge to excel in your future career. <br />
            </p>
          </center>
          <center>
            {" "}
            <h2 style={{ fontSize: "23px", fontFamily: "Tilt Neon" }}>
              Key Highlights:
            </h2>
          </center>

          <p
            style={{
              margin: "2vh 4vh",

              fontSize: "2.3vh",
            }}
          >
            <ul style={{ listStyle: "square" }}>
              <li>
                100% Pass Guarantee: We are so confident in the quality of our
                training that we offer a 100% pass guarantee for the CPC
                certification exam. Your success is our success. Placement
                Assistance: Landing your first job in the medical coding field
                can be challenging, which is why we provide placement support to
                our students. We have strong connections with leading healthcare
                organizations to help kickstart your career.
              </li>
              <li>
                Location: Conveniently located in Madhapur, Hyderabad, our
                institute offers a conducive learning environment with modern
                facilities and experienced instructors. Why Choose Kriger
                Medical Coding Institute?
              </li>
              <li>
                Experienced Faculty: Our instructors bring years of practical
                experience to the classroom, ensuring that you receive the best
                education and real-world insights. Comprehensive Content: Our
                training materials cover the entire spectrum of medical coding,
                equipping you with the knowledge and skills to excel in your
                career. Proven Track Record: With a 7+ year track record of
                excellence, our students have consistently achieved their CPC
                certification and secured promising careers.
              </li>
            </ul>{" "}
            Join us at Kriger Medical Coding Institute and embark on your
            journey to becoming a certified medical coder. We are committed to
            your success, and our 100% pass guarantee is a testament to our
            dedication to your future. Contact us today to take the first step
            towards a rewarding career in medical coding.
          </p>
        </div>
      </main>
      <>
        <Contactus />
      </>
      <>
        <Studentcard2 />
      </>
    </div>
  );
};

export default InsideWhyKriger;
