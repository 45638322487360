import React from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./Components/Home/Home";
import Certifications from "./Pages/Certifications/Certifications";
import WhyKriger from "./Pages/whyKriger/WhyKriger";
import Error from "./Pages/404/Error";
import CodingndBilling from "./Pages/Medicalcodingandbilling/CodingndBilling";
import MedicalSpeciality from "./Pages/MedicalSeciality/MedicalSpeciality";
import WhyCode from "./Pages/Whycode/WhyCode";
import Ahiima from "./Pages/Ahima/Ahiima";
import Australianclinicalcoding from "./Pages/Australia/Australianclinicalcoding";
import Navbar from "./Navbar/Navbar";
import Topbar from "./Components/Topbar/Topbar";
import Scroll from "./Components/scroll/Scroll";

const App = () => {
  return (
    <div>
      <Router>
        <Topbar />
        <Navbar />
        <Scroll />

        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/cpc-certification" element={<Certifications />} />
          <Route
            path="/medical-coding-nd-billing"
            element={<CodingndBilling />}
          />
          <Route path="/why-kriger-coding" element={<WhyKriger />} />

          <Route path="/error" element={<Error />} />
          <Route path="*" element={<Error />} />
          <Route path="/contact-us" element={<Error />} />
          <Route path="/reach-us-now" element={<Error />} />
          <Route path="/medical-speciality" element={<MedicalSpeciality />} />
          <Route path="/why-code" element={<WhyCode />} />
          <Route path="/medical-speciality" element={<MedicalSpeciality />} />
          <Route path="/ahima" element={<Ahiima />} />
          <Route
            path="/australian-clinical-coding"
            element={<Australianclinicalcoding />}
          />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
