import React from "react";
import Faqcertification from "./Faqcertification";
import Contactus from "../../Components/contactUS/Contactus";
import Studentcard2 from "../../Components/StudentCards/Studentcard2";
const InsideCertification1 = () => {
  return (
    <div style={{ backgroundColor: "#FFFFEF" }}>
      <main>
        <div className="position-relative  p-3 p-md-1 m-md-0 text-center ">
          <div className="col-md-8 p-lg-0 mx-auto my-5">
            <h1
              className="display-4 fw-normal"
              style={{ fontSize: "25px", fontFamily: "Tilt Neon" }}
            >
              CPC Certification Training Institute Madhapur
            </h1>
            <p className="">
              The Certified Professional Coder (CPC) is the gold standard for
              medical coding in a physician office setting. The CPC
              certification exam tests the competencies required to perform the
              job of a professional coder who specializes in coding for services
              performed by physicians and non-physician providers (eg, nurse
              practitioners and physician assistants). Individuals who earn the
              CPC credential have proven expertise in physician/non-physician
              provider documentation review, abstract professional provider
              encounters, coding proficiency with CPT®, HCPCS Level II,
              ICD-10-CM, and compliance and regulatory requirements for
              physician services.
            </p>
            <a
              className="btn btn-outline-secondary"
              href="https://www.aapc.com/certifications/cpc"
            >
              Know More{" "}
            </a>
          </div>
          <div className="product-device shadow-sm d-none d-md-block" />
          <div className="product-device product-device-2 shadow-sm d-none d-md-block" />
        </div>
        <>
          <div className="position-relative  p-3 p-md-1 m-md-0 text-center ">
            <div className="product-device shadow-sm d-none d-md-block" />
            <div className="product-device product-device-2 shadow-sm d-none d-md-block" />
          </div>
          <div
            className="d-md-flexflex-md-2 w-100  ps-md-3"
            style={{ justifyContent: "center" }}
          >
            <center>
              {" "}
              <h2
                style={{
                  fontSize: "23px",
                  fontFamily: "Tilt Neon",
                  marginTop: "-5vh",
                }}
              >
                Key Highlights:
              </h2>
            </center>

            <p
              style={{
                margin: "2vh 4vh",

                fontSize: "2.3vh",
              }}
            >
              <ul style={{ listStyle: "square" }}>
                <li>
                  100% Pass Guarantee: We are so confident in the quality of our
                  training that we offer a 100% pass guarantee for the CPC
                  certification exam. Your success is our success. Placement
                  Assistance: Landing your first job in the medical coding field
                  can be challenging, which is why we provide placement support
                  to our students. We have strong connections with leading
                  healthcare organizations to help kickstart your career.
                </li>
                <li>
                  Location: Conveniently located in Madhapur, Hyderabad, our
                  institute offers a conducive learning environment with modern
                  facilities and experienced instructors. Why Choose Kriger
                  Medical Coding Institute?
                </li>
                <li>
                  Experienced Faculty: Our instructors bring years of practical
                  experience to the classroom, ensuring that you receive the
                  best education and real-world insights. Comprehensive Content:
                  Our training materials cover the entire spectrum of medical
                  coding, equipping you with the knowledge and skills to excel
                  in your career. Proven Track Record: With a 7+ year track
                  record of excellence, our students have consistently achieved
                  their CPC certification and secured promising careers.
                </li>
              </ul>{" "}
            </p>
          </div>
          <div className="d-md-flexflex-md-2 w-100  ps-md-3">
            <center>
              {" "}
              <h2 style={{ fontSize: "23px", fontFamily: "Tilt Neon" }}>
                How do I get my CPC certification?
              </h2>
            </center>

            <p
              style={{
                margin: "2vh 4vh",

                fontSize: "2.3vh",
              }}
            >
              The CPC® credential is the most recognized medical coding
              certification in the healthcare industry.
              <br />
              <b>This is achieved in four steps.</b>
              <ol style={{ listStyle: "square" }}>
                <li>Step 1 Pass the CPC® Exam.</li>
                <li>Step 2 Become and Maintain AAPC Membership</li>
                <li>Step 3 Verify Your Experience.</li>
                <li>
                  Step 4 Maintain Certification Through Continuing Education
                  Units (CEUs)
                </li>
              </ol>{" "}
            </p>
          </div>
          <div className="d-md-flexflex-md-2 w-100  ps-md-3">
            <center>
              {" "}
              <h2 style={{ fontSize: "23px", fontFamily: "Tilt Neon" }}>
                How much does Medical Coding Training Fees Hyderabad?
              </h2>
            </center>

            <p
              style={{
                margin: "2vh 4vh",

                fontSize: "2.3vh",
              }}
            >
              <center>
                Fees for Medical coding 28,000+ only. If you are Looking for CPC
                certification training,
              </center>
            </p>
          </div>
        </>
        <>
          <Faqcertification />
        </>
      </main>
      <>
        <Contactus />
      </>
      <>
        <Studentcard2 />
      </>
    </div>
  );
};

export default InsideCertification1;
