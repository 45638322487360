import React from "react";
import "./Topbar.css";
const Topbar = () => {
  return (
    <div class="topbar">
      <div>
        <h3>M:+91-95026 13666, 70750 40344</h3>
      </div>
      <div>
        <h3>E-mail: info@krigerindia.com</h3>
      </div>
      <div>
        <h3>
          Timigs: Mon-Sat
          <br />
          9.30 Am to 6.00 PM
        </h3>
      </div>
    </div>
  );
};

export default Topbar;
