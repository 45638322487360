import React from "react";
import Topbar from "../../Components/Topbar/Topbar";
import Navbar from "../../Navbar/Navbar";
import Contactus from "../../Components/contactUS/Contactus";
import Studentcard2 from "../../Components/StudentCards/Studentcard2";
import Footer from "../../Components/Footer/Footer";
import InsideWhycode from "./InsideWhycode";
const WhyCode = () => {
  return (
    <div style={{ backgroundColor: "#FFFFEF" }}>
      <>
        <Topbar />
      </>
      <>
        <Navbar />
      </>

      <>
        <>
          <InsideWhycode />
        </>
      </>
      <>
        <Contactus />
      </>
      <>
        <Studentcard2 />
      </>
      <>
        <Footer />
      </>
    </div>
  );
};

export default WhyCode;
