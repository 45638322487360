import React from "react";
import "./Hero.css";
import webdev from "../../Assets/8252365_3835119-removebg.png";
import { useTypewriter, Cursor } from "react-simple-typewriter";
/* import bg4 from "../../Assets/image_processing20221126-23797-ho4lj6.gif";
 */ import About from "../About/About";
import Courses from "../Courses/Courses";
import Testimonial from "../Testimonials/Testimonial";
import Faq from "../Faq/Faq";
import Studentcard2 from "../StudentCards/Studentcard2";
import Navbar from "../../Navbar/Navbar";
import Contactus from "../contactUS/Contactus";
const Hero = () => {
  const [text] = useTypewriter({
    words: [
      "CPC Certification Trainig With 100% Pass Guaranteed Training",
      "Medical Coding Training & Placement",
      " Exclusive training program tailored for Fresher's",
    ],
    loop: Infinity,
  });
  return (
    <>
      <div style={{ backgroundColor: "#FFFFEF" }}>
        <div id="hero" className="d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <h1 style={{ fontSize: "27px", color: "#b64d1df4" }}>
                  Best Medical Coding Training Institute Hyderabad
                </h1>
                <h3
                  style={{ fontSize: "20px", color: "gray", fontWeight: "900" }}
                >
                  We are a team of young, dynamic and go-getter Trainer's
                  <br />
                  We Provide Industry Best{" "}
                  <span
                    style={{
                      color: "Orange",
                      fontSize: "17px",
                      fontFamily: "Tilt Neon",
                      fontWeight: "bolder",
                    }}
                  >
                    {text}
                  </span>
                  <Cursor cursorColor="red" />
                </h3>

                <div className="d-flex justify-content-center justify-content-lg-start">
                  <button className="btn-get-started ">Get Started</button>
                </div>
              </div>
              <div
                className="col-lg-6 order-1 order-lg-2 hero-img"
                data-aos="zoom-in"
                data-aos-delay={200}
              >
                <img
                  src={webdev}
                  className="img-fluid animated hero-img"
                  alt=""
                  width={"450px"}
                  height={"fit-content"}
                  style={{ float: "right" }}
                />
              </div>
            </div>
          </div>
        </div>
        <>
          <About />
        </>
        <>
          <Courses />
        </>
        <>
          <Testimonial />
        </>
        <>
          <Studentcard2 />
        </>
        <>
          <Contactus />
        </>
        <Faq />
      </div>
    </>
  );
};

export default Hero;
