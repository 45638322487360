import React from "react";
import Topbar from "../../Components/Topbar/Topbar";
import Navbar from "../../Navbar/Navbar";
import Contactus from "../../Components/contactUS/Contactus";
import Studentcard2 from "../../Components/StudentCards/Studentcard2";
import Footer from "../../Components/Footer/Footer";
import Insideahima from "./Insideahima";
const Ahiima = () => {
  return (
    <div style={{ backgroundColor: "#FFFFEF" }}>
      <>
        <Insideahima />
      </>
      <>
        <Contactus />
      </>
      <>
        <Studentcard2 />
      </>
      <>
        <Footer />
      </>
    </div>
  );
};

export default Ahiima;
