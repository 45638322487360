import React from "react";
import kriger from "../../Assets/logo-wide-removebg-preview (1).png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div id="footer" style={{ lineHeight: "2.5vh", backgroundColor: "#fff" }}>
      {/* Footer */}
      <footer className="text-center text-lg-start  text-muted">
        {/* Section: Social media */}
        <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
          {/* Left */}
          <div className="me-5 d-none d-lg-block">
            <span>Get connected with us on social networks:</span>
          </div>
          {/* Left */}
          {/* Right */}
          <div>
            <a href="" className="me-4 text-reset">
              <i className="fab fa-facebook-f" />
            </a>
            <a href="" className="me-4 text-reset">
              <i className="fab fa-twitter" />
            </a>
            <a href="" className="me-4 text-reset">
              <i className="fab fa-google" />
            </a>
            <a href="" className="me-4 text-reset">
              <i className="fab fa-instagram" />
            </a>
            <a href="" className="me-4 text-reset">
              <i className="fab fa-linkedin" />
            </a>
            <a href="" className="me-4 text-reset">
              <i className="fab fa-github" />
            </a>
          </div>
          {/* Right */}
        </section>
        {/* Section: Social media */}
        {/* Section: Links  */}
        <section className="">
          <div className="container text-center text-md-start mt-5">
            {/* Grid row */}
            <div className="row mt-3">
              {/* Grid column */}
              <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                {/* Content */}
                <h6 className="text-uppercase fw-bold mb-4">
                  <img
                    src={kriger}
                    width={"200px"}
                    height={"auto"}
                    alt="kriger-logo"
                  />{" "}
                </h6>
                <p style={{ textAlign: "justify" }}>
                  Ready to kickstart your career in medical coding? Register
                  with Kriger Medical Coding Centre now by filling out our
                  simple form.
                  <br />
                  <p style={{ textIndent: "70px" }}>
                    Join us and take the first step toward a successful journey
                    in the healthcare industry!
                  </p>
                </p>
              </div>
              {/* Grid column */}
              {/* Grid column */}
              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                {/* Links */}
                <h6 className="text-uppercase fw-bold mb-4">Services</h6>
                <p>
                  <Link to={"/medical-coding-nd-billing"}>
                    {" "}
                    Medical Coding & Billing
                  </Link>
                </p>
                <p>
                  <Link to={"/certifications"}>CPC Certification</Link>
                </p>
                <p>
                  <Link to={"/medical-speciality"}>
                    {" "}
                    Medical Speciality Training
                  </Link>
                </p>
                <p>
                  <Link to={"/error"}>
                    AHIMA UK Certified Coding Specialist
                  </Link>
                </p>
                <p>
                  <a href="#!" className="text-reset"></a>
                </p>
                <p>
                  <Link to={"/error"}>Australian Clinial Coding</Link>
                </p>
              </div>
              {/* Grid column */}
              {/* Grid column */}
              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                {/* Links */}
                <h6 className="text-uppercase fw-bold mb-4">Useful links</h6>
                <p>
                  <Link to={"/"}>Home</Link>
                </p>
                <p>
                  <Link to={"/cpc-certification"}>Our Courses</Link>
                </p>
                <p>
                  <Link to={"/cpc-certification"}>Certifications</Link>
                </p>
                <p>
                  <Link to={"/why-kriger"}>Why Kriger</Link>
                </p>
                <p>
                  <Link to={"*"}>Contact Us </Link>
                </p>
              </div>
              {/* Grid column */}
              {/* Grid column */}
              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                {/* Links */}
                <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
                <p>
                  <i className="fas fa-home me-3" /> Flat B3,3rd Floor,KVR Enclave,Beside Gurudwara,Ameerpet Hyderabad 500016
                </p>
                <p>
                  <i className="fas fa-envelope me-3" />
                  info@krigerindia.com
                </p>
                <p>
                  <i className="fas fa-phone me-3" /> +91 95026 13666
                </p>
              </div>
              {/* Grid column */}
            </div>
            {/* Grid row */}
          </div>
        </section>
        {/* Section: Links  */}
        {/* Copyright */}
        <div
          className="text-center p-4"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
        >
          © 2023 Copyright:
          <a className="text-reset fw-bold" href="">
            krigerindia.com
          </a>
        </div>
        {/* Copyright */}
      </footer>
      {/* Footer */}
    </div>
  );
};

export default Footer;
