import React from "react";

const InsideWhycode = () => {
  return (
    <div style={{ backgroundColor: "#FFFFEF" }}>
      {" "}
      <div
        className="d-md-flexflex-md-2 w-100  ps-md-3"
        style={{ justifyContent: "center" }}
      >
        <center>
          {" "}
          <h2
            style={{
              fontSize: "23px",
              fontFamily: "Tilt Neon",
              marginTop: "2vh",
            }}
          >
            Why We Code?
          </h2>
        </center>

        <p
          style={{
            margin: "2vh 4vh",

            fontSize: "2.3vh",
          }}
        >
          <center>
            {" "}
            Let’s start with a simple question about medical coding: Why do we
            code medical reports? Wouldn’t it be enough to list the symptoms,
            diagnoses, and procedures, send them to an insurance company, and
            wait to hear which services will be reimbursed? Medical Coding
            Training in Hyderabad To answer that, we have to look at the massive
            amount of data that every patient visit entails. If you go into the
            doctor with a sore throat, and present the doctor with symptoms like
            a fever, sore throat, and enlarged lymph nodes, these will be
            recorded, along with the procedures the doctor performs and the
            medicine the doctor prescribes. In a straightforward case like this,
            the doctor will only officially report his diagnosis, but that still
            means the portion of that report that will be coded contains a
            diagnosis, a procedure, and a prescription. Take a step back, and
            this is suddenly a lot of very specific information. And that’s just
            for a relatively simple doctor’s visit. What happens when a patient
            comes into the doctor with a complicated injury or sickness, like an
            ocular impairment related to their Type-2 diabetes? As injuries,
            conditions, and illnesses get more complex, the amount of data that
            needs to be conveyed to insurance companies increases significantly.
            According to the Centers for Disease Control (CDC), there were over
            1.4 billion patient visits in the past year. That’s a stat that
            includes visits to physician offices, hospital outpatient facilities
            and emergency rooms. If there were just five pieces of coded
            information per visit, which is an almost unrealistically low
            estimate, that’d be 6 billion individual pieces of information that
            needs to be transferred every year. In a system loaded with data,
            medical coding allows for the efficient transfer of huge amounts of
            information. Coding also allows for uniform documentation between
            medical facilities. The code for streptococcal sore throat is the
            same in Arkansas as it is in Hawaii. Having uniform data allows for
            efficient research and analysis, which government and health
            agencies use to track health trends much more efficiently. If the
            CDC, for example, wants to analyze the prevalence of viral
            pneumonia, they can search for the number of recent pneumonia
            diagnoses by looking for the ICD-10-CM code. Finally, coding allows
            administrations to look at the prevalence and effectiveness of
            treatment in their facility. This is especially important to large
            medical facilities like hospitals. Like government agencies
            tracking, say, the incidence of a certain disease, medical
            facilities can track the efficiency of their practice by analyzing
            Now that we understand the importance of this practice, let’s take a
            look at the three types of code that you’ll have to become familiar
            with as a medical coder
          </center>
        </p>
      </div>
    </div>
  );
};

export default InsideWhycode;
