import React from "react";
import "./Studentcard.css";
import Akshay from "../../Assets/studentposters/Akshay.jpeg";
import Srilakshmi from "../../Assets/studentposters/Srilakshmi.jpeg";
import Nagasree from "../../Assets/studentposters/Nagasree.jpeg";
import bindumadhavi from "../../Assets/studentposters/Bindhu Madhavi 1.jpeg";
import accesshealthcare from "../../Assets/accesshealthcare-logo-400x400-removebg-preview.png";
import advantum from "../../Assets/Advantum-Health-removebg-preview.png";
import chaitanya from "../../Assets/studentposters/chaitanya.jpg";
import krishna from "../../Assets/studentposters/krishna.jpg";
import muralidhar from "../../Assets/studentposters/muralidhar.jpg";
import satish from "../../Assets/studentposters/Sathish.jpeg";
import sravanthi from "../../Assets/studentposters/sravanthi.jpeg";
import srilekha from "../../Assets/studentposters/Srilekha.jpeg";
import eclat from "../../Assets/ECLAT_logo.webp";
import avontix from "../../Assets/avontix-removebg-preview.png";
import cotiviti from "../../Assets/cotiviti-removebg-preview.png";
import agshealth from "../../Assets/download-removebg-preview.png";
import omega from "../../Assets/ome-300x300-removebg-preview.png";
import gebbs from "../../Assets/GeBBS_Logo_Large.webp";
import elico from "../../Assets/b5945660-6984-4f90-8cbe-a8ba7067597d_.png";
import corrohealth from "../../Assets/CorroHealth-Hiring-2021-removebg-preview.png";
import veetch from "../../Assets/Vee_Technologies-removebg-preview.png";
import primera from "../../Assets/primera.png";
import gopi from "../../Assets/studentposters/Gopi.jpeg";
import praveen from "../../Assets/studentposters/Praveen.jpeg";
import Ramya from "../../Assets/studentposters/Ramya.jpeg";
import padmavathi from "../../Assets/studentposters/padmavathi.jpeg";
import Shirisha from "../../Assets/studentposters/shirisha.jpeg";
import srinivas from "../../Assets/studentposters/srinivas.jpeg";

const Studentcard2 = () => {
  return (
    <div>
      <center>
        {" "}
        <h2 style={{ fontSize: "26px", fontFamily: "Tilt Neon" }}>
          {" "}
          Kriger Certified Students
        </h2>
      </center>
      <div
        className=""
        style={{
          display: "flex",
          width: "100%",
          flexWrap: "Wrap",
          padding: "2vh",
          justifyContent: "space-between",
          margin: "auto  ",
        }}
      >
        <div className="" style={{ marginBottom: "3vh", display: "flex" }}>
          <marquee loop="infinite" Scrollamount="7">
            <img
              src={Akshay}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Srilakshmi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Nagasree}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={bindumadhavi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={chaitanya}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={krishna}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={muralidhar}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={satish}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={sravanthi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={srilekha}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />

            <img
              src={praveen}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={gopi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Ramya}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={padmavathi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Shirisha}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={srinivas}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Akshay}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Srilakshmi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Nagasree}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={bindumadhavi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={chaitanya}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={krishna}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={muralidhar}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={satish}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={sravanthi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={srilekha}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />

            <img
              src={praveen}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={gopi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Ramya}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={padmavathi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Shirisha}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={srinivas}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Akshay}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Srilakshmi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Nagasree}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={bindumadhavi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={chaitanya}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={krishna}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={muralidhar}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={satish}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={sravanthi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={srilekha}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />

            <img
              src={praveen}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={gopi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Ramya}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={padmavathi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Shirisha}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={srinivas}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Akshay}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Srilakshmi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Nagasree}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={bindumadhavi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={chaitanya}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={krishna}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={muralidhar}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={satish}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={sravanthi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={srilekha}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />

            <img
              src={praveen}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={gopi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Ramya}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={padmavathi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Shirisha}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={srinivas}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Akshay}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Srilakshmi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Nagasree}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={bindumadhavi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={chaitanya}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={krishna}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={muralidhar}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={satish}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={sravanthi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={srilekha}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />

            <img
              src={praveen}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={gopi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Ramya}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={padmavathi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Shirisha}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={srinivas}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Akshay}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Srilakshmi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Nagasree}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={bindumadhavi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={chaitanya}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={krishna}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={muralidhar}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={satish}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={sravanthi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={srilekha}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />

            <img
              src={praveen}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={gopi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Ramya}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={padmavathi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Shirisha}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={srinivas}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Akshay}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Srilakshmi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Nagasree}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={bindumadhavi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={chaitanya}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={krishna}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={muralidhar}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={satish}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={sravanthi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={srilekha}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />

            <img
              src={praveen}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={gopi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Ramya}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={padmavathi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Shirisha}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={srinivas}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Akshay}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Srilakshmi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Nagasree}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={bindumadhavi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={chaitanya}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={krishna}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={muralidhar}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={satish}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={sravanthi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={srilekha}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />

            <img
              src={praveen}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={gopi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Ramya}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={padmavathi}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={Shirisha}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
            <img
              src={srinivas}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "1vh", position: "sticky" }}
              alt="slider-image"
            />
          </marquee>
        </div>
      </div>
      <div>
        <div style={{ marginTop: "2vh" }}>
          <>
            <center>
              {" "}
              <h2 style={{ fontSize: "26px", fontFamily: "Tilt Neon" }}>
                {" "}
                Our Clients
              </h2>
            </center>
          </>{" "}
          <marquee
            loop="infinite"
            direction="right"
            style={{ marginLeft: "1vh", marginRight: "1vh" }}
          >
            <img
              src={accesshealthcare}
              width={"170vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={advantum}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "0.5vh" }}
              alt="slider-image"
            />
            <img
              src={eclat}
              width={"150vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={elico}
              width={"140vh"}
              height={"auto"}
              style={{ marginRight: "0.6vh" }}
              alt="slider-image"
            />

            <img
              src={cotiviti}
              width={"170vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={avontix}
              width={"150vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={agshealth}
              width={"170vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={primera}
              width={"120vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={corrohealth}
              width={"140vh"}
              height={"auto"}
              style={{ marginRight: "1.5vh" }}
              alt="slider-image"
            />
            <img
              src={gebbs}
              width={"100vh"}
              height={"auto"}
              style={{ marginRight: "1.5vh" }}
              alt="slider-image"
            />
            <img
              src={veetch}
              width={"150vh"}
              height={"auto"}
              style={{ marginRight: "0.5vh" }}
              alt="slider-image"
            />
            <img
              src={omega}
              width={"160vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={accesshealthcare}
              width={"170vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={advantum}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "0.5vh" }}
              alt="slider-image"
            />
            <img
              src={eclat}
              width={"150vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={elico}
              width={"140vh"}
              height={"auto"}
              style={{ marginRight: "0.6vh" }}
              alt="slider-image"
            />

            <img
              src={cotiviti}
              width={"170vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={avontix}
              width={"150vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={agshealth}
              width={"170vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={primera}
              width={"120vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={corrohealth}
              width={"140vh"}
              height={"auto"}
              style={{ marginRight: "1.5vh" }}
              alt="slider-image"
            />
            <img
              src={gebbs}
              width={"100vh"}
              height={"auto"}
              style={{ marginRight: "1.5vh" }}
              alt="slider-image"
            />
            <img
              src={veetch}
              width={"150vh"}
              height={"auto"}
              style={{ marginRight: "0.5vh" }}
              alt="slider-image"
            />
            <img
              src={omega}
              width={"160vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={accesshealthcare}
              width={"170vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={advantum}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "0.5vh" }}
              alt="slider-image"
            />
            <img
              src={eclat}
              width={"150vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={elico}
              width={"140vh"}
              height={"auto"}
              style={{ marginRight: "0.6vh" }}
              alt="slider-image"
            />

            <img
              src={cotiviti}
              width={"170vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={avontix}
              width={"150vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={agshealth}
              width={"170vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={primera}
              width={"120vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={corrohealth}
              width={"140vh"}
              height={"auto"}
              style={{ marginRight: "1.5vh" }}
              alt="slider-image"
            />
            <img
              src={gebbs}
              width={"100vh"}
              height={"auto"}
              style={{ marginRight: "1.5vh" }}
              alt="slider-image"
            />
            <img
              src={veetch}
              width={"150vh"}
              height={"auto"}
              style={{ marginRight: "0.5vh" }}
              alt="slider-image"
            />
            <img
              src={omega}
              width={"160vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={accesshealthcare}
              width={"170vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={advantum}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "0.5vh" }}
              alt="slider-image"
            />
            <img
              src={eclat}
              width={"150vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={elico}
              width={"140vh"}
              height={"auto"}
              style={{ marginRight: "0.6vh" }}
              alt="slider-image"
            />

            <img
              src={cotiviti}
              width={"170vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={avontix}
              width={"150vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={agshealth}
              width={"170vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={primera}
              width={"120vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={corrohealth}
              width={"140vh"}
              height={"auto"}
              style={{ marginRight: "1.5vh" }}
              alt="slider-image"
            />
            <img
              src={gebbs}
              width={"100vh"}
              height={"auto"}
              style={{ marginRight: "1.5vh" }}
              alt="slider-image"
            />
            <img
              src={veetch}
              width={"150vh"}
              height={"auto"}
              style={{ marginRight: "0.5vh" }}
              alt="slider-image"
            />
            <img
              src={omega}
              width={"160vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={accesshealthcare}
              width={"170vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={advantum}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "0.5vh" }}
              alt="slider-image"
            />
            <img
              src={eclat}
              width={"150vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={elico}
              width={"140vh"}
              height={"auto"}
              style={{ marginRight: "0.6vh" }}
              alt="slider-image"
            />

            <img
              src={cotiviti}
              width={"170vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={avontix}
              width={"150vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={agshealth}
              width={"170vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={primera}
              width={"120vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={corrohealth}
              width={"140vh"}
              height={"auto"}
              style={{ marginRight: "1.5vh" }}
              alt="slider-image"
            />
            <img
              src={gebbs}
              width={"100vh"}
              height={"auto"}
              style={{ marginRight: "1.5vh" }}
              alt="slider-image"
            />
            <img
              src={veetch}
              width={"150vh"}
              height={"auto"}
              style={{ marginRight: "0.5vh" }}
              alt="slider-image"
            />
            <img
              src={omega}
              width={"160vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={accesshealthcare}
              width={"170vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={advantum}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "0.5vh" }}
              alt="slider-image"
            />
            <img
              src={eclat}
              width={"150vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={elico}
              width={"140vh"}
              height={"auto"}
              style={{ marginRight: "0.6vh" }}
              alt="slider-image"
            />

            <img
              src={cotiviti}
              width={"170vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={avontix}
              width={"150vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={agshealth}
              width={"170vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={primera}
              width={"120vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={corrohealth}
              width={"140vh"}
              height={"auto"}
              style={{ marginRight: "1.5vh" }}
              alt="slider-image"
            />
            <img
              src={gebbs}
              width={"100vh"}
              height={"auto"}
              style={{ marginRight: "1.5vh" }}
              alt="slider-image"
            />
            <img
              src={veetch}
              width={"150vh"}
              height={"auto"}
              style={{ marginRight: "0.5vh" }}
              alt="slider-image"
            />
            <img
              src={omega}
              width={"160vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={accesshealthcare}
              width={"170vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={advantum}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "0.5vh" }}
              alt="slider-image"
            />
            <img
              src={eclat}
              width={"150vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={elico}
              width={"140vh"}
              height={"auto"}
              style={{ marginRight: "0.6vh" }}
              alt="slider-image"
            />

            <img
              src={cotiviti}
              width={"170vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={avontix}
              width={"150vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={agshealth}
              width={"170vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={primera}
              width={"120vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={corrohealth}
              width={"140vh"}
              height={"auto"}
              style={{ marginRight: "1.5vh" }}
              alt="slider-image"
            />
            <img
              src={gebbs}
              width={"100vh"}
              height={"auto"}
              style={{ marginRight: "1.5vh" }}
              alt="slider-image"
            />
            <img
              src={veetch}
              width={"150vh"}
              height={"auto"}
              style={{ marginRight: "0.5vh" }}
              alt="slider-image"
            />
            <img
              src={omega}
              width={"160vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={accesshealthcare}
              width={"170vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={advantum}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "0.5vh" }}
              alt="slider-image"
            />
            <img
              src={eclat}
              width={"150vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={elico}
              width={"140vh"}
              height={"auto"}
              style={{ marginRight: "0.6vh" }}
              alt="slider-image"
            />

            <img
              src={cotiviti}
              width={"170vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={avontix}
              width={"150vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={agshealth}
              width={"170vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={primera}
              width={"120vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={corrohealth}
              width={"140vh"}
              height={"auto"}
              style={{ marginRight: "1.5vh" }}
              alt="slider-image"
            />
            <img
              src={gebbs}
              width={"100vh"}
              height={"auto"}
              style={{ marginRight: "1.5vh" }}
              alt="slider-image"
            />
            <img
              src={veetch}
              width={"150vh"}
              height={"auto"}
              style={{ marginRight: "0.5vh" }}
              alt="slider-image"
            />
            <img
              src={omega}
              width={"160vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={accesshealthcare}
              width={"170vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={advantum}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "0.5vh" }}
              alt="slider-image"
            />
            <img
              src={eclat}
              width={"150vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={elico}
              width={"140vh"}
              height={"auto"}
              style={{ marginRight: "0.6vh" }}
              alt="slider-image"
            />

            <img
              src={cotiviti}
              width={"170vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={avontix}
              width={"150vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={agshealth}
              width={"170vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={primera}
              width={"120vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={corrohealth}
              width={"140vh"}
              height={"auto"}
              style={{ marginRight: "1.5vh" }}
              alt="slider-image"
            />
            <img
              src={gebbs}
              width={"100vh"}
              height={"auto"}
              style={{ marginRight: "1.5vh" }}
              alt="slider-image"
            />
            <img
              src={veetch}
              width={"150vh"}
              height={"auto"}
              style={{ marginRight: "0.5vh" }}
              alt="slider-image"
            />
            <img
              src={omega}
              width={"160vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={accesshealthcare}
              width={"170vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={advantum}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "0.5vh" }}
              alt="slider-image"
            />
            <img
              src={eclat}
              width={"150vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={elico}
              width={"140vh"}
              height={"auto"}
              style={{ marginRight: "0.6vh" }}
              alt="slider-image"
            />

            <img
              src={cotiviti}
              width={"170vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={avontix}
              width={"150vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={agshealth}
              width={"170vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={primera}
              width={"120vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={corrohealth}
              width={"140vh"}
              height={"auto"}
              style={{ marginRight: "1.5vh" }}
              alt="slider-image"
            />
            <img
              src={gebbs}
              width={"100vh"}
              height={"auto"}
              style={{ marginRight: "1.5vh" }}
              alt="slider-image"
            />
            <img
              src={veetch}
              width={"150vh"}
              height={"auto"}
              style={{ marginRight: "0.5vh" }}
              alt="slider-image"
            />
            <img
              src={omega}
              width={"160vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={accesshealthcare}
              width={"170vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={advantum}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "0.5vh" }}
              alt="slider-image"
            />
            <img
              src={eclat}
              width={"150vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={elico}
              width={"140vh"}
              height={"auto"}
              style={{ marginRight: "0.6vh" }}
              alt="slider-image"
            />

            <img
              src={cotiviti}
              width={"170vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={avontix}
              width={"150vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={agshealth}
              width={"170vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={primera}
              width={"120vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={corrohealth}
              width={"140vh"}
              height={"auto"}
              style={{ marginRight: "1.5vh" }}
              alt="slider-image"
            />
            <img
              src={gebbs}
              width={"100vh"}
              height={"auto"}
              style={{ marginRight: "1.5vh" }}
              alt="slider-image"
            />
            <img
              src={veetch}
              width={"150vh"}
              height={"auto"}
              style={{ marginRight: "0.5vh" }}
              alt="slider-image"
            />
            <img
              src={omega}
              width={"160vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={accesshealthcare}
              width={"170vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={advantum}
              width={"200vh"}
              height={"auto"}
              style={{ marginRight: "0.5vh" }}
              alt="slider-image"
            />
            <img
              src={eclat}
              width={"150vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={elico}
              width={"140vh"}
              height={"auto"}
              style={{ marginRight: "0.6vh" }}
              alt="slider-image"
            />

            <img
              src={cotiviti}
              width={"170vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={avontix}
              width={"150vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={agshealth}
              width={"170vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={primera}
              width={"120vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
            <img
              src={corrohealth}
              width={"140vh"}
              height={"auto"}
              style={{ marginRight: "1.5vh" }}
              alt="slider-image"
            />
            <img
              src={gebbs}
              width={"100vh"}
              height={"auto"}
              style={{ marginRight: "1.5vh" }}
              alt="slider-image"
            />
            <img
              src={veetch}
              width={"150vh"}
              height={"auto"}
              style={{ marginRight: "0.5vh" }}
              alt="slider-image"
            />
            <img
              src={omega}
              width={"160vh"}
              height={"auto"}
              style={{ marginRight: "1vh" }}
              alt="slider-image"
            />
          </marquee>
        </div>
      </div>
    </div>
  );
};

export default Studentcard2;
